import React, { Component } from 'react';

class SynthVisualizer extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidUpdate() {
    this.draw();
  }

  draw() {
    const canvas = this.canvas.current;
    const height = canvas.height;
    const width = canvas.width;
    const context = canvas.getContext('2d');
    let x = 1;
    x = x+12;

  //background ellipse grid
    for (var bgW=0; bgW < width; bgW = bgW+10) {
      for (var bgH=0; bgH < height; bgH = bgH+10) {
         context.fillRect(50,200,width,height);
//         context.noStroke();
         context.ellipse(bgW,bgH,1,1, 40, 10, 60);
       }
     }
    
    //left, background bezier fill group
    for (var i=0; i<100; i++){
        context.fillRect(50,0,i);
        context.bezierCurveTo(50,width,i/2,i*10,200,-x/2,200,-400*.5);
    }
     
  //  noFill(); 
     
    //left bezier group, descending, disappears
    if (x<800){
      for (var i=255; i>0; i--){
        context.strokeRect(30,i,i);
        context.bezierCurveTo(0,height,i/10,i*2,width,x,i,i*4);
      } 
    }
    
    //right bezier group, disappears
    for (var i=255; i>0 && i<100; i--){
      context.strokeRect(20,i,i);
      context.bezierCurveTo(400,height,i*10,i/2,width,-x,i,i*4);
    }
    
    //center bezier group  
    for (var i=255; i>0; i--){
      context.strokeRect(60,i,i);
      context.bezierCurveTo(150,height,i*10,i/2,width,-x,i,i*4);
    }
    
    //far left bezier group, red and yellow
    for (var i=0; i<100; i++){
      context.strokeRect(i,100,100);
     context.bezierCurveTo(i,x,i/2,x,i*3,x,i+1,i*4);
    }

    //key press resets x to 0
    if (this.props)
    {
      x = 0;
    }

  }

  render() {
    return <canvas id='synth-viz' className='synth-visualizer' ref={this.canvas}> </canvas>;
  }

}

export default SynthVisualizer;