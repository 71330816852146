import React, { Component } from 'react';
import SynthVisualizer from './SynthVisualizer';
import './synth.css';

class Synth extends Component {
	constructor(props) {
		super(props)
		this.state = {
			controlLabels: ['vol', 'wave', 'mode', 'hold'],
			controlValues: [],
			controlSliderMax: ['100', '3', '3', '1'],
			controlSliderDefault: ['25', '0', '0', '0']
		};
	}

	componentDidMount() {
		this.initSynth();

		this.controlLabels = this.state.controlLabels;
		this.controlValues = [];

		for (var i=0; i < this.controlLabels.length; ++i)
			this.controlValues.push(0);

		console.log(this.controlValues);

//		this.setState({controlValues: controlValues});
	}

	componentDidUnMount() {
		this.audioContext.close();
	}

	generateSliders() {
		this.controlLabels = this.state.controlLabels;
		this.controlSliderMax = this.state.controlSliderMax;
		this.controlSliderDefault = this.state.controlSliderDefault;
		var sliders = [];

		for (var i=0; i < this.controlLabels.length; ++i)
		{
			sliders.push(
				<div className="slider-holder" id={this.controlLabels[i] + '-d'}>
		          	<input id={this.controlLabels[i]}
		            type="range"
		            defaultValue={this.controlSliderDefault[i]}
		            min="0"
		            max={this.controlSliderMax[i]}
		            className="sliders"
		            onChange={this.handleSliderChange.bind(this)}
		            step="1" />
        		</div>
			);
		}
		return sliders;
	}

	handleSliderChange(event) {
    	console.log(event.target.value, " ", event.target.id);
    	var id = event.target.id;
    	var value = event.target.value;
    	const controlValues = this.state.controlValues;

    	this.setState({ controlValues: controlValues });

    	console.log('id is ' + id);
    	console.log('value is ' + value);

    	switch(id) {
    		default:
    		case 'vol':
    			this.changeVolume(value);
    			break;
    		case 'wave':
    			this.assignWaveform(value);
    			break;
    		case 'mode':
    			this.assignMode(value);
    			break;
    		case 'hold':
    			this.assignHold(value);
    			break;
    	}
  	}

  	initSynth() {
  		if (this.audioContext === null || this.audioContext === undefined) {
  			this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

  			this.gain = this.audioContext.createGain();
  			this.gain.gain.value = .25;
  			
  			this.oscType = 'sine'; // ['sine', 'square', 'sawtooth', 'triangle']

			this.freqKey1 = 261.63;
  			this.freqKey2 = 440;
  			this.freqKey3 = 493.88;
  			this.freqKey4 = 659.3;
  			this.freqKey5 = 784;

			this.keyFrequency = this.freqKey1;

			this.releaseTime = 3.0;

			this.gain.connect(this.audioContext.destination);
  		} else {
  			this.audioContext.resume().then(() => {
  				console.log('resumed successfully');
  			})
  		}
  	}

  	changeVolume(value) {
  		console.log(this.state.controlValues);

  		console.log('change volume to ' + value);
  		console.log(typeof value);
  		
  		let volume = parseFloat(value/100);

  		if (isFinite(volume)) {
			let now = this.audioContext.currentTime;
			this.gain.gain.setValueAtTime(volume, now);
		}
  	}

  	assignWaveform(wave) {
  		switch (wave) {
  			default:
		case '0':
			this.oscType = 'sine';
			break;
		case '1':
			this.oscType = 'square';
			break;
		case '2':
			this.oscType = 'sawtooth';
			break;
		case '3':
			this.oscType = 'triangle';
			break;
  		}

  		if (this.oscillator)
	  		this.oscillator.type = this.oscType;
  	}

  	assignMode(mode){
		switch(mode){
			default:
			case '0':
				this.freqKey1 = 261.63; // C4
				this.freqKey2 = 440; // A
				this.freqKey3 = 493.88; // B
				this.freqKey4 = 659.3; // E5
				this.freqKey5 = 784; // G5
				console.log('case 0');
				break;
			case '1':
				this.freqKey1 = 203.9; // 
				this.freqKey2 = 347.4; // 
				this.freqKey3 = 498.0; // 
				this.freqKey4 = 648.7; // 
				this.freqKey5 = 782.5; // G5
				console.log('case 1');
				break;
			case '2':
				this.freqKey1 = 702.0; // 
				this.freqKey2 = 386.3; // 
				this.freqKey3 = 968.6; // 
				this.freqKey4 = 470.8; // 
				this.freqKey5 = 155.1; // 
				break;
			case '3':
				this.freqKey1 = 275.0; // 
				this.freqKey2 = 305.0; // 
				this.freqKey3 = 370.0; // 
				this.freqKey4 = 403.0; // 
				this.freqKey5 = 506.0; // 
				break;
		}
	}

	assignHold(value) {
		console.log('change hold value');

		switch(value) {
			default:
			case '0':
				this.releaseTime = .5;
				break;
			case '1':
				this.releaseTime = 2.5;
				break;
		}
	}
	
	playKey(keyNumber) {
		console.log('key is ' + keyNumber);
	
		switch(keyNumber) {
			default:
			case 0:
				this.keyFrequency = this.freqKey1;
				break;
			case 1:
				this.keyFrequency = this.freqKey2;
				break;
			case 2:
				this.keyFrequency = this.freqKey3;
				break;
			case 3:
				this.keyFrequency = this.freqKey4;
				break;
			case 4:
				this.keyFrequency = this.freqKey5;
				break;
		}

//		this.gain.gain.value = .25;

		this.oscillator = this.audioContext.createOscillator();
		this.oscillator.type = this.oscType;

		this.oscillator.frequency.value = this.keyFrequency;
		this.oscillator.connect(this.gain);

		this.oscillator.start(this.audioContext.currentTime);
		this.oscillator.stop(this.audioContext.currentTime + this.releaseTime);
//		this.gain.gain.setValueAtTime(this.gain.gain.value, this.audioContext.currentTime);
//		this.gain.gain.exponentialRampToValueAtTime(0.0001, this.audioContext.currentTime + this.releaseTime);

		console.log(this.gain.gain.value);
	}

  	render() {
	  	return(
	  	<div id="synth-component">
		  	<div className="centered-container">
		  		<div className='synth-viz-holder'>
		  			<SynthVisualizer />
		  		</div>

		  		<div className="slider-section">
					{this.generateSliders()}
				</div>

				<div id="key-container">

				<ul>
					<li className="synth-key" id="key1" key="key1" 
						onClick={() => this.playKey(0)}>
						</li>
					<li className="synth-key" id="key2" key="key2" onClick={() => this.playKey(1)}>
						</li>
					<li className="synth-key" id="key3" key="key3" onClick={() => this.playKey(2)}>
						</li>
					<li className="synth-key" id="key4" key="key4" onClick={() => this.playKey(3)}>
						</li>
					<li className="synth-key" id="key5" key="key5" onClick={() => this.playKey(4)}>
						</li>
				</ul>
			</div>
			</div>
			
			
		</div>

	  	);	 
	} 	
}

export default Synth;


