import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import './card.css';

class CardInfo extends Component {
  renderCard(card) {
    return (
      <div className="card-song-preview">      
      	<iframe className="card-song-preview-iframe" src={card.bc_src} title={card.title} seamless></iframe>
      </div>
    );}
 
  renderInfo(card) {
    return (
    	<div>
	      <ul className="list-unstyled">
	      	<li>
	      		<h4><a href={card.bc_href} target="_blank" rel="noopener noreferrer">{card.title}</a></h4>
	      	</li>
	      	<li>
	      		{card.name}
	      	</li>
	      	<li>
	      		{card.label} {card.year}
	      	</li>
	      	<br />
          {card.description ? <li><div>{card.description}</div></li> : ''}
          <br />
          {card.credits ? <li><h5>credits</h5></li> : ''}
          {card.credits ? <li><div>{card.credits}</div></li> : ''}
          <br />
          {card.moreInfo ? <li><h5>additional</h5></li> : ''}
          {card.moreInfo ? <li><div>{card.moreInfo}</div></li> : ''}
	      </ul>
      </div>
    );
  }
  
  render() {
    const card = this.props.card;
    if (card != null)
      return (

  		<Container className="card-info" id="listen">
  			<Row className="card-detail-holder">
  				<Col>
	              {this.renderCard(card)}
            	</Col>
            	<Col className="card-detail-area">
	              {this.renderInfo(card)}
            	</Col>
        	</Row>
        </Container>
      );
    else return <div></div>;
  }
}
 
export default CardInfo;