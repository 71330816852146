import audioTrack from './audio/Genau.ogg';
import audioTrack2 from './audio/BeardsCleaner.ogg';
import audioTrack3 from './audio/Plateau.ogg';
import audioTrack4 from './audio/BirdDance.ogg';
import audioTrack5 from './audio/CapturingWildAnimals.ogg';
import audioTrack6 from './audio/Voyage.ogg';
import background from './img/AAE_Cover.jpg';

export const playlist = [
    {
        id: 0,
        track: "Genau",
        artist: "Hermis Chalet",
        album: "Alles auf Einmal",
        source: "./audio/Genau.ogg",
        static: "/static/media/Genau.6bb748ee.ogg",
        bc: "https://resmith.bandcamp.com/music",
        media: { audioTrack },
        artwork: { background }
    },
    {
        id: 1,
        track: "Beards Cleaner",
        artist: "Hermis Chalet",
        album: "Alles auf Einmal",
        source: "./audio/BeardsCleaner.ogg",
        static: "/static/media/BeardsCleaner.78cc21ec.ogg",
        bc: "https://resmith.bandcamp.com/music",
        media: { audioTrack2 },
        artwork: { background }
    },
    {
    id: 2,
    track: "Plateau",
    artist: "R.E. Smith",
    album: "On Little Farm",
    source: "./audio/Plateau.ogg",
    static: "/static/media/Plateau.f4e4c7d4.ogg",
    bc: "https://resmith.bandcamp.com/music",
    media: {audioTrack3},
    artwork: {background}
  },
  {
    id: 3,
    track: "Voyage",
    artist: "R.E. Smith",
    album: "On Little Farm",
    source: "./audio/Voyage.ogg",
    static: "/static/media/Voyage.f3ac9d94.ogg",
    bc: "https://resmith.bandcamp.com/music",
    media: {audioTrack4},
    artwork: {background}
  },
  {
    id: 4,
    track: "Bird Dance",
    artist: "Cat Calendar",
    album: "Fantasy",
    source: "./audio/BirdDance.ogg",
    static: "/static/media/BirdDance.0f3a8e45.ogg",
    bc: "https://catcalendar.bandcamp.com/",
    media: {audioTrack5},
    artwork: {background}
  },
  {
    id: 5,
    track: "Capturing Wild Animals",
    artist: "Cat Calendar",
    album: "Fantasy",
    source: "./audio/CapturingWildAnimals.ogg",
    static: "/static/media/CapturingWildAnimals.ff1e8470.ogg",
    bc: "https://catcalendar.bandcamp.com/",
    media: {audioTrack6},
    artwork: {background}
  }
  ];