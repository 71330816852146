import React from 'react';
import { Navbar, NavItem, NavLink } from 'reactstrap';
import logo from './img/cowgrazing_w.png';

const AppNav = ({activeTab, onTabChange}) => {
	const itemClass = (tabName) => `
		App-nav-item ${
			(activeTab === tabName) ? 'selected' : ''
		}`;

	return (
		<header className="App-nav">
			<Navbar id="App-navbar" color="transparent" light expand="md" sticky="top">
				<NavItem className="app-nav-item1">
					<NavLink id="nav-home" className={itemClass('olf')} to="/olf" onClick={() => onTabChange('olf')}><img id="logo" src={logo} alt="logo"/></NavLink>
				</NavItem>
				
				<h4 style={{paddingRight:'100px'}}>R.E. Smith</h4>
				<NavItem className="app-nav-item3">
					<NavLink id="nav-audio" className={itemClass('audio')} to="/audio" onClick={() => onTabChange('audio')}>Audio</NavLink>
				</NavItem>
				<NavItem className="app-nav-item4">
					<NavLink id="nav-wtf" className={itemClass('wtf')} to="/wtf" onClick={() => onTabChange('wtf')}>Other</NavLink>
				</NavItem>
				
			</Navbar>
		</header>
	);
};

export default AppNav;