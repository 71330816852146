import React from 'react';

function Home() {
	return (
		<div className="wrapper" style={{ minHeight: '1200px'}}>
			<section>
	        </section>
	        <section>
            	<div className="intro-text centered-container"></div>
            	 <div className="background-container">
            
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
            </div>
	
        	</section>
   		</div>
	);
}

export default Home;