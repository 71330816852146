import React, { Component } from 'react';
import AudioVisualizer from './AudioVisualizer';

class AudioAnalyser extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    	audioData: new Uint8Array(0)
    };
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.createCtx();
  }

  createCtx() {
    if (this.audioContext === null || this.audioContext === undefined) {
    this.audioContext =  new (window.AudioContext || window.webkitAudioContext)();
    this.analyser = this.audioContext.createAnalyser();

    this.analyser.minDecibels = -90;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;
    this.analyser.fftSize = 2048;
    this.bufferLength = this.analyser.fftSize;

    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

    this.source = this.audioContext.createMediaElementSource(this.props.audio);    
    this.source.connect(this.analyser);
    this.source.connect(this.audioContext.destination);
      
    this.rafId = requestAnimationFrame(this.tick);
    } else {
      this.audioContext.resume().then(() => {
          console.log('Playback resumed successfully');
        });
    }
  }

  tick() {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rafId);

    if (this.analyser)
    	this.analyser.disconnect();

	  if (this.source)
	    this.source.disconnect();
  }

  render() {
    return (
    	<div>
    		<AudioVisualizer audioData={this.state.audioData} />
    	</div>
    );
  }
}

export default AudioAnalyser;