import React, { Component } from 'react';
import background from './img/fantasy_alt10.jpg';
import Synth from './Synth';

class WTFPage extends Component {


	render() {
		return (
			<div className="wrapper" style={{ minHeight: '1200px'}}>


				<section>
	        	<div className="image-area" style={{ backgroundImage: `url(${background})`, paddingTop:'200px'}}></div>

	        	<div className="page-header centered-container">OTHER OTHER OTHER</div>
	            	<div className="intro-text centered-container">
	        	        Other Other Other 
	    	        </div>
		        </section>
		        <section id="synth-section">
		        	<div>
	 					
	 				</div>
				</section>
		    </div>
		);
	}
}

export default WTFPage;