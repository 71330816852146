/*
{
    id: 
    name: 
    href1: "#",
    href2: "#",
    bc_src: 
    bc_href: 
    type: 
    year: 
    label: 
    title: 
    description: 
  }
*/

export const CARDS = [
    {
      id: 0, // 
      name: "Hermis Chalet",
      href1: "https://resmith.bandcamp.com/music",
      href2: "https://resmith.bandcamp.com/music",
      bc_src: "https://bandcamp.com/EmbeddedPlayer/album=2287461330/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3896251579/transparent=true/",
      bc_href: "https://resmith.bandcamp.com/album/alles-auf-einmal",
      type: "R.E. Smith",
      year: "2022", // 3 Sep 2022
      label: "",
      title: "Alles auf Einmal",
      class: "card-filter-preview-feature",
      credits: "R Smith, with J Carpenter (violin, viola, cello) and W Grim (contrabass)",
      description: "",
      moreInfo: ""
    },
    {
    id: 1, // AM-007
    name: "R.E. Smith",
    href1: "https://resmith.bandcamp.com/music",
    href2: "https://resmith.bandcamp.com/music",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=2226927778/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3896251579/transparent=true/",
    bc_href: "https://resmith.bandcamp.com/album/on-little-farm",
    type: "R.E. Smith",
    year: "2020", // 19 May 2020
    label: "Amaryllis Recordings",
    title: "On Little Farm",
    class: "card-filter-preview-feature",
    credits: "R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 2,
    name: "The Lickets", // IC-018-A
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=324879761/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2592266379/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/the-human-dimension",
    type: "International Corporation",
    year: "2020", // 01 Dec 2020
    label: "International Corporation",
    title: "The Human Dimension",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 3, //
    name: "Less Bells",
    href1: "https://lessbells.bandcamp.com/",
    href2: "https://lessbells.bandcamp.com/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=742890240/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2942629029/transparent=true/",
    bc_href: "https://lessbells.bandcamp.com/album/hypnagogia",
    type: "Collaborations",
    year: "2020",
    label: "[Artist-Released]",
    title: "Hypnagogia",
    class: "card-filter-preview-collab",
    credits: "J Carpenter, with others",
    description: "",
    moreInfo: ""
  },
  {
    id: 4, //
    name: "Wayne Grim",
    href1: "https://waynegrim.com/",
    href2: "https://waynegrim1.bandcamp.com/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1466691014/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/",
    bc_href: "https://waynegrim1.bandcamp.com/album/the-grinding",
    type: "Collaborations",
    year: "2020",
    label: "[Artist-Released]",
    title: "The Grinding 研削",
    class: "card-filter-preview-collab",
    credits: 'W Grim, with R Smith (drums on "Arrangement")',
    description: "",
    moreInfo: ''
  },
  {
    id: 5, //
    name: "Cat Calendar",
    href1: "https://catcalendar.bandcamp.com/",
    href2: "https://catcalendar.bandcamp.com/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=665077158/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=546839478/transparent=true/",
    bc_href: "https://catcalendar.bandcamp.com/album/fantasy",
    type: "R.E. Smith",
    year: "2019", // May 2019
    label: "[Artist-Released]",
    title: "Fantasy",
    class: "card-filter-preview-feature",
    credits: "R Smith, with M Greer (vocals), W Grim (bass), N Landis (guitar)",
    description: "",
    moreInfo: ""
  },
{
    id: 6, // IC-017-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=236891139/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=1307924691/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/round-the-red-lamp",
    type: "International Corporation",
    year: "2018", // 08 Dec 2018
    label: "International Corporation",
    title: "Round the Red Lamp",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 31, //
    name: "Less Bells",
    href1: "https://lessbells.bandcamp.com/",
    href2: "https://lessbells.bandcamp.com/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=173003881/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3690523564/transparent=true/",
    bc_href: "https://lessbells.bandcamp.com/album/solifuge-2",
    type: "Collaborations",
    year: "2018", // ???
    label: "Kranky",
    title: "Solifuge",
    class: "card-filter-preview-collab",
    credits: "J Carpenter, with others",
    description: "",
    moreInfo: ''
  },
  {
    id: 30, //
    name: "Wayne Grim",
    href1: "https://waynegrim.com/",
    href2: "https://waynegrim1.bandcamp.com/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=4246424049/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=754298602/transparent=true/",
    bc_href: "https://waynegrim1.bandcamp.com/album/in-decline",
    type: "Collaborations",
    year: "2018", // ???
    label: "[Artist-Released]",
    title: "In Decline",
    class: "card-filter-preview-collab",
    credits: "W Grim, with others",
    description: "",
    moreInfo: ''
  },
  {
    id: 8, // IC-015-A
    name: "Mitch Greer",
    href1: "http://mitchgreer.com/",
    href2: "#",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1771967145/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=4187230443/transparent=true/",
    bc_href: "https://mitchgreer.bandcamp.com/album/computer-music",
    type: "Collaborations",
    year: "2018",
    label: "[Artist-Released]",
    title: "Computer Music",
    class: "card-filter-preview-collab",
    credits: 'M Greer, with R Smith (flute on the track "Flute in Cabin")',
    description: 'aka Norbert Englebart',
    moreInfo: ''
  },
  {
    id: 7, // IC-014-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1635452077/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2463793326/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/offering-to-magnetic-mountain",
    type: "International Corporation",
    year: "2017", // 03 Feb 2017
    label: "International Corporation",
    title: "Offering to Magnetic Mountain",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 21, //
    name: "The Same",
    href1: "https://sameindustries.bandcamp.com/",
    href2: "https://sameindustries.bandcamp.com/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=3548516604/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=1337924047/transparent=true/",
    bc_href: "https://ratskinrecords.bandcamp.com/album/rogue-pulse-gravity-collapse-benefit-compilation",
    type: "Other Projects",
    year: "", // ???
    label: "",
    title: "The Same",
    class: "card-filter-preview",
    credits: "W Grim, N Landis, R Smith",
    description: "Masque on Rogue Pulse / Gravity Collapse Benefit Compilation (Ratskin Records, 2017)",
    moreInfo: "The Same also appear on Plastic Island Volume Four: Songs About Cats (Compilation) - Catis Felis (2020)"
  },
  {
    id: 9, // IC-013-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=2537791539/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3876060361/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/dolls-in-color",
    type: "International Corporation",
    year: "2015", // 25 Sep 2015
    label: "International Corporation",
    title: "Dolls in Color",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith; with A Hoos, H Hjersman",
    description: "",
    moreInfo: ""
  },
  {
    id: 10, // IC-011-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=710555468/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2393465735/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/here-on-earth",
    type: "International Corporation",
    year: "2011", // 09 Aug 2011
    label: "International Corporation",
    title: "Here (on Earth)",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 11, // IC-010-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1459469178/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=1890263044/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/song-of-the-clouds",
    type: "International Corporation",
    year: "2010", // 26 Oct 2010
    label: "International Corporation",
    title: "Song of the Clouds",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 12, // IC-008-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=2208585442/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2788289761/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/eidolons",
    type: "International Corporation",
    year: "2010", // 26 Oct 2010
    label: "International Corporation",
    title: "Eidolons",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith; with S Origer-Steiff",
    description: "",
    moreInfo: ""
  },
  {
    id: 13, // IC-007-A
    name: "Burning Paradise of the Floating Moon",
    href1: "http://mitchgreer.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://www.youtube.com/embed/zVIUWbzFABI",
    bc_href: "http://internationalcorporation.net/",
    type: "International Corporation",
    year: "2009", // 01 Oct 2009
    label: "International Corporation",
    title: "C4",
    class: "card-filter-preview-collab",
    credits: "M Greer, with J Carpenter, R Smith, S Origer-Steiff",
    description: '',
    moreInfo: ''
  },
  {
    id: 14, // IC-006-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=907221753/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3840179797/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/they-turned-our-desert-into-fire",
    type: "International Corporation",
    year: "2009", // 21 Jun 2009
    label: "International Corporation / Gandhara Recordings",
    title: "They Turned our Desert into Fire",
    class: "card-filter-preview-main",
    credits: "L Buell, M Greer, R Smith, with D Stetch-Homsy",
    description: "",
    moreInfo: ""
  },
  {
    id: 15, // IC-005-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1504542167/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2611888993/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/her-name-came-on-arrows",
    type: "International Corporation",
    year: "2009", // 21 Jun 2009
    label: "International Corporation / Gandhara Recordings",
    title: "Her Name Came on Arrows",
    class: "card-filter-preview-main",
    credits: "L Buell, M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 5,
    name: "Mary St John",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1566385265/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=1365183824/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/some-leaves-turned-red-some-still-green",
    type: "International Corporation",
    year: "2005",
    label: "International Corporation",
    title: "Some Leaves Turned Red, Some Still Green",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "",
    moreInfo: ""
  },
  {
    id: 16, // IC-003-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=1853633627/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=920982324/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/journey-in-caldecott",
    type: "International Corporation",
    year: "2007", // 04 Sep 2007
    label: "International Corporation",
    title: "Journey in Caldecott",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith, with E Dombroski",
    description: "",
    moreInfo: ""
  },
  {
    id: 17, // IC-001-A ??
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "https://bandcamp.com/EmbeddedPlayer/album=3468222929/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=4085332935/transparent=true/",
    bc_href: "https://internationalco.bandcamp.com/album/fake-universe-man",
    type: "International Corporation",
    year: "2005",
    label: "International Corporation",
    title: "Fake Universe Man",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith, with J Arkenberg, S Marks",
    description: "",
    moreInfo: ""
  },
  {
    id: 18, // IC-002-A
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "",
    bc_href: "",
    type: "International Corporation",
    year: "2005", // ??? Jan 2005
    label: "International Corporation",
    title: "Apartment Tree",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith",
    description: "Out of print",
    moreInfo: ""
  },
  {
    id: 19, // 
    name: "The Lickets",
    href1: "http://www.lickets.com/",
    href2: "http://internationalcorporation.net/",
    bc_src: "",
    bc_href: "",
    type: "International Corporation",
    year: "1999", // ??? 2000? 
    label: "International Corporation / Sandwich Records",
    title: "Beginner's Guide To ...",
    class: "card-filter-preview-main",
    credits: "M Greer, R Smith; featuring OED (G Cole, M Greer, S Marks, A Sprinkles) and D Willingham",
    description: "Out of print",
    moreInfo: ""
  },
  {
    id: 22, //
    name: "Transona Five",
    href1: "https://sonicsurgeryrecords.bigcartel.com/product/transona-five-double-lp-pre-order",
    href2: "https://sonicsurgeryrecords.bigcartel.com/product/transona-five-double-lp-pre-order",
    bc_src: "https://www.youtube.com/embed/C4N6TYymXIA",
    bc_href: "https://sonicsurgeryrecords.bigcartel.com/product/transona-five-double-lp-pre-order",
    type: "Other Projects",
    year: "", // 
    label: "Sonic Surgery Records",
    title: "Transona Five",
    class: "card-filter-preview",
    credits: "C Anderson, G Cole, C Foley, S Marks, R Smith, A Sprinkles, with J Carpenter, S Danbom, M Greer, M Pence, D Willingham",
    description: "Transona Five Double LP remaster of previously released and some unreleased material.",
    moreInfo: 'Going Away EP (Drawing Room Records, 1999); Duffel Bag (Sandwich Records, 1998); Polyphase 1 Split 7" (Sleepin Corporate, 1997); ' +
    'Melatonin Bullet (Sandwich Records, 1997); Schrasj/Transona Five Split 7" (Sandwich Records, 1997); Mariposa / Reconstitute 7" (Sandwich Records, 1996)'
  }
];