/*
   	<div className="image-area" style={{ backgroundImage: `url(${background})`, paddingTop: '200px'}}></div>
	<div className="page-header centered-container">HOME HOME HOME HOME</div>
*/

import React from 'react';
import AudioPlayer from './AudioPlayer';

function OLF() {
	return (
		<div className="wrapper" style={{ minHeight: '1200px'}}>
			<section>
				<AudioPlayer />
	        </section>
	        <section>
            	<div className="intro-text centered-container"></div>	        	
        	</section>
   		</div>
	);
}

export default OLF;