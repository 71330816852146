import React, { useState } from 'react';
import Nav from './Nav';
import AppFooter from './Footer';
import Home from './Home';
import OLF from './OLF';
import AudioPage from './AudioPage';
import WTF from './WTFPage';
import './App.css';

const App = () => {
	const [activeTab, setActiveTab] = useState('home');

	return (
		<div className="App">
			<Nav 
				activeTab={activeTab}
				onTabChange={setActiveTab}

			/>
			<main className="App-content">
				<Content tab={activeTab} />
			</main>
			
			<AppFooter
				activeTab={activeTab}
				onTabChange={setActiveTab}
			/>

		</div>
	); 
};

const Content = ({tab}) => {
	switch (tab) {
		default:
		case 'home':
			return <Home />;
		case 'olf':
			return <OLF />;
		case 'audio':
			return <AudioPage />;
		case 'other':
			return <WTF />;
		case 'wtf':
			return <WTF />;
	}

}

export default App;