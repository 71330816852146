/*
this.state.audio.getTracks().forEach(track => track.stop());

<button id="start-visuals-button" onClick={this.toggleVisuals}>
                  {this.state.status === 'playing' ? 'Pause audio' : 'Play audio'}
                </button>
          {this.state.status === 'playing' ? <AudioAnalyzer audio={this.state.audio} /> : <div style={{backgroundColor: 'gray', height: '300px'}}></div>}
*/

import React, { Component } from 'react';
import { Button, ButtonGroup, ButtonDropdown, 
  DropdownToggle, DropdownMenu, DropdownItem, Container, Row, Col } from 'reactstrap';
import AudioAnalyzer from './AudioAnalyzer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlay, faPause , faStop } from '@fortawesome/free-solid-svg-icons';
import './AudioPlayer.css';
import { playlist } from './playlist';
import cover1 from './img/fantasy_cover.jpg';
import cover2 from './img/OLF_h.jpg';
import cover3 from './img/AAE_Cover.jpg';

class AudioPlayer extends Component{
  constructor(props) {
    super(props);
    this.state = {
      audio: null,
      status: 'stopped',
      dropdownOpen: false,
      trackSelection: 0
    };
    this.audioRef = React.createRef();

    this.toggleAudio = this.toggleAudio.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
  }

  componentDidMount() {
    this.getAudio();
  }

  componentDidUpdate() {

  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  updateSong = (song) => {
    var audioSource = document.getElementById('audio-source');

    audioSource.src = playlist[song].static;

    this.highlightCover(song);

    if (this.audioRef.current) {
      this.audioRef.current.pause();
      this.audioRef.current.load();
      this.audioRef.current.play();
    }

    this.setState({ 
      status: 'playing', 
      trackSelection: song
    });
  }

  highlightCover(song) {
    let first = document.querySelector(".first-cover");
      let second = document.querySelector(".second-cover");
      let third = document.querySelector(".third-cover");

      if (song < 2) {
          first.style.border = "#99F443 5px solid";
          second.style.border = "black 0px solid";
          third.style.border = "black 0px solid";
      } else if (song < 4) {
          second.style.border = "#99F443 5px solid";
          first.style.border = "black 0px solid";
          third.style.border = "black 0px solid";
      } else {
          first.style.border = "black 0px solid";
          second.style.border = "black 0px solid";
          third.style.border = "#99F443 5px solid";
      }
  }

  // list songs in playlist
  renderPlaylist() {
    return (
        <div>
            <h5 style={{ paddingLeft: '10px' }}>Alles auf Einmal</h5>
            <DropdownItem onClick={() => this.updateSong(0)} dropdownvalue='AAE'>{playlist[0].track}</DropdownItem>
            <DropdownItem onClick={() => this.updateSong(1)} dropdownvalue='AAE'>{playlist[1].track}</DropdownItem>

          <h5 style={{paddingLeft:'10px'}}>On Little Farm</h5>
          <DropdownItem onClick={() => this.updateSong(2)} dropdownvalue='OLF'>{playlist[2].track}</DropdownItem>
          <DropdownItem onClick={() => this.updateSong(3)} dropdownvalue='OLF'>{playlist[3].track}</DropdownItem>
          
          <h5 style={{paddingTop:'20px', paddingLeft:'10px'}}>Fantasy</h5>
          <DropdownItem onClick={() => this.updateSong(4)} dropdownvalue='Fantasy'>{playlist[4].track}</DropdownItem>
          <DropdownItem onClick={() => this.updateSong(5)} dropdownvalue='Fantasy'>{playlist[5].track}</DropdownItem>
        </div>
      );
  }

  renderAlbums() {
    return (
      <Container>
            <Row>
                <Col>
                    <div className="feature-album-preview">
                        <div className="feature-album-image first-cover" onClick={() => this.updateSong(0)}
                            style={{ backgroundImage: `url(${cover3})` }}></div>
                    </div>
                </Col>
            <Col>
              <div className="feature-album-preview">
                <div className="feature-album-image second-cover" onClick={() => this.updateSong(2)}
                  style={{ backgroundImage: `url(${cover2})`}}></div>
              </div>
            </Col>
            <Col>
              <div className="feature-album-preview">                  
                <div className="feature-album-image third-cover" onClick={() => this.updateSong(4)}
                  style={{ backgroundImage: `url(${cover1})`}}></div>
              </div>
            </Col>
          </Row>
        </Container>
    );}

  async getAudio() {
    const audio = document.querySelector('#player-audio');
    this.setState({audio:audio});
  }

  playAudio() {
    var audioElement = document.getElementById('player-audio');

    if (this.audioRef.current) {
//      this.audioRef.current.pause();
//      this.audioRef.current.load();
      this.audioRef.current.play();
    }

    this.setState({ 
      status: 'playing',
      audio: audioElement
    });
  }

  pauseAudio() {
    const audio = document.querySelector('#player-audio');
    audio.pause();

    this.setState({status: 'paused'});
  }

  stopAudio() {
    const audio = document.querySelector('#player-audio');
    audio.pause();
    audio.currentTime = 0;

    this.setState({status: 'paused'});
  }

  toggleAudio() {
    if (this.state.audio) {
      if (this.state.status === 'playing') {
        this.pauseAudio();
      } else {
        this.playAudio();
      }

    } else {
      this.getAudio();
    }
  }
  
  render() {
    return (
      <div className="audio-player-holder">
        <div >
          <audio id='player-audio' ref={this.audioRef}>
            <source id='audio-source' src={playlist[0].static} type="audio/ogg" controlsList="nodownload" />
          </audio>
        <div className='audio-analyzer-holder'>
        <div className="background-container">
            
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
            </div>
          <div className='audio-analyzer-frame'>
            {this.state.audio ? <AudioAnalyzer audio={this.state.audio} /> : ''}
          </div>
        </div>
        <div className="audio-player-controls">
          <ButtonGroup>
            <Button id="play-pause-audio" onClick={this.toggleAudio}>
                {this.state.status === 'playing' ? <FontAwesomeIcon icon={faPause} size="lg"/> : <FontAwesomeIcon icon={faPlay} size="lg"/>}
            </Button>
            <Button id="stop-audio" onClick={this.stopAudio}><FontAwesomeIcon icon={faStop} size="lg"/></Button>
            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle id="player-dropdown" caret>
                Select Track
              </DropdownToggle>
              <DropdownMenu className='track-selection-dropdown'>
                {this.renderPlaylist()}
              </DropdownMenu>
            </ButtonDropdown>
            <div id="track-playing-name">
              :: {playlist[this.state.trackSelection].artist} :: {playlist[this.state.trackSelection].album} :: {playlist[this.state.trackSelection].track} :: <a href={playlist[this.state.trackSelection].bc} target="_blank" rel="noopener noreferrer">&#60;&#60; get &#62;&#62;</a>
            </div>
          </ButtonGroup>
          
        </div>

        <div className='feature-album-holder'>
              {this.renderAlbums()}
        </div>

        </div>
      </div>
    );
  }

}

export default AudioPlayer;