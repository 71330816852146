import React from 'react';
import { Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faInstagram , } from '@fortawesome/free-brands-svg-icons';

const AppFooter = ({activeTab, onTabChange}) => {
	const itemClass = (tabName) => `
		App-nav-item ${
			(activeTab === tabName) ? 'selected' : ''
	}`;

	return (
		<footer>
			<Container className="App-footer">
				<Row>
					<Col xs="3">
						<Nav className="footer-nav-main" vertical>
							<NavItem className="footer-nav-main-item">
								<NavLink id="footer-home" className={itemClass('home')} onClick={() => onTabChange('home')}>Home</NavLink>
							</NavItem>
							<NavItem className="footer-nav-main-item">
								<NavLink id="footer-audio" className={itemClass('audio')} onClick={() => onTabChange('audio')}>Audio</NavLink>
							</NavItem>
							<NavItem className="footer-nav-main-item">
								<NavLink id="footer-other" className={itemClass('other')} onClick={() => onTabChange('other')}>Other</NavLink>
							</NavItem>
						</Nav>
					</Col>
					<Col>
					</Col>
					<Col>
						<Nav className="footer-nav-other" vertical>
							<h5 style={{marginLeft:'12px', marginTop: '25px', fontSize: '1.2em'}}>Other Places</h5>
							<NavItem className="footer-nav-other-item">
								<NavLink href="http://www.lickets.com/" target="_blank" rel="noopener noreferrer">The Lickets</NavLink>
							</NavItem>
							<NavItem className="footer-nav-other-item">
								<NavLink href="http://internationalcorporation.net/" target="_blank" rel="noopener noreferrer">
									International Corporation</NavLink>
							</NavItem>
							<NavItem className="footer-nav-other-item">
								<NavLink href="https://www.amaryllisrecordings.com/" target="_blank" rel="noopener noreferrer">Amaryllis Recordings</NavLink>
							</NavItem>
						</Nav>
					</Col>
					<Col>
						<Nav className="footer-nav" vertical>
							<NavItem style={{marginTop:'50px', marginLeft: '20px'}}>
								<NavLink href="https://www.instagram.com/hermischalet/" 
								target="_blank" rel="noopener noreferrer" onClick={() => onTabChange('ig')}>
									<FontAwesomeIcon icon={faInstagram} size="lg"/></NavLink>
							</NavItem>
						</Nav>
					</Col>
				</Row>
			</Container>

			<div id="copyright">
				&#169; 2022 R.E. Smith
			</div>		
		</footer>
	);
};

export default AppFooter;