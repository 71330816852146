import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: 'G-97Q6C3RLVP'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')	
);

reportWebVitals();

