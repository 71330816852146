import React, { Component } from 'react';
import './HomePage.css';
import Filter from './FILTER';
import CardInfo from './CardInfo';
import { CARDS } from './cards';
import background from './img/fantasy_alt.jpg';


class AudioPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cards: CARDS,
			selectedCard: null,
			cardFilter: null
		};
	}

	cardSelect(cardId) {
		this.setState ({ 
			selectedCard: cardId
		});
	}

	cardFilterSelect(filterName) {
		this.setState({
			cardFilter: filterName
		});
	}

	render() {
		return (
			<div className="wrapper" style={{ minHeight: '1200px'}}>


				<section>
	        	<div className="image-area" style={{ backgroundImage: `url(${background})`}}></div>

	        	<div className="page-header centered-container">Audio</div>
		            <div className="intro-text centered-container">
		                Select a project and recording for audio previews and more info. 
		            </div>
		        </section>
		        <section>
		        	<Filter
		        		cards={this.state.cards}
		        		cardSelect={cardId => this.cardSelect(cardId)}
		        		filter={this.state.cardFilter}
		        		cardFilterSelect={filterName => this.cardFilterSelect(filterName)}
					/>

					<CardInfo 
						card={
							this.state.cards.filter(
								card => card.id === this.state.selectedCard
							)[0]
						}
					/>
					

				</section>
		    </div>
		);
	}
}

export default AudioPage;