/*

optional - add to ButtonGroup


*/

import React, { Component } from 'react';
import { Container, Row, Col, Card, ButtonGroup, Button } from 'reactstrap';
import { FILTERS } from './filters';
import './card.css';

class Filter extends Component {
  render() {
    const card = this.props.cards
      .filter(({ type }) => {
        return !this.props.filter || type === this.props.filter;
      })
      .map(card => {
        return (
          <div
            key={Math.random()
              .toString(36)
              .slice(2)}
          >
          <a href="#listen" style={{textDecoration: 'none'}}>
            <Card body onClick={() => this.props.cardSelect(card.id)} className={card.class}>
              <div className="sm-card-text-area">
                <div className="sm-card-title">{card.title}</div>
                <div className="sm-card-name">{card.name} <br /><br />{card.year}</div>
              </div>
            </Card>
            </a>
          </div>
        );
      });
    const filterButtons = FILTERS.map(filterName => {
      return (
        <Button className="filter-buttons audio-preview-overlay"
          key={filterName}
          onClick={() => this.props.cardFilterSelect(filterName)}
        >
          {filterName}
        </Button>
      );
    });
 
    return (
    	<section>
	    	<Container className="filter-section">
	    		<Row>
	        		<Col xs="3">
			          	<div className="filter-section-header">Projects</div>
                  <div className="filter-project-buttons">
			            <ButtonGroup size="lg" vertical>
                    <Button className="filter-buttons" onClick={() => this.props.cardFilterSelect(null)}>
                      All
                    </Button>

			              {filterButtons}
			            </ButtonGroup>
                  </div>
					</Col>

					<Col>
						<div className="filter-section-header">Recordings</div>
						<div className="filter-card-holder">{card}</div>
					</Col>
	        	</Row>
			</Container>
		</section>
    );
  }
}

export default Filter;